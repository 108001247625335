<route>
  {
    "meta": {
      "permission": [
        "equipements.view_maintenance",
        "equipements.change_maintenance"
      ]
    }
  }
</route>

<template>
  <v-container fluid>
    <i-table
      :title="tableTitle"
      :headers="headers"
      app="equipements.maintenance"
      api="maintenance"
      @click:create="changeRoute({ pk: 'create' })"
      @click:edit="changeRoute"
      :opt="{ equipement_id: equipmentId }"
    >
      <template v-slot:item.date="{ item }">
        <span>{{ dateFormat(item.date) }}</span>
      </template>
      <template v-slot:item.next_date="{ item }">
        <span>{{ dateFormat(item.next_date) }}</span>
      </template>
    </i-table>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'maintenance',
  data() {
    return {
      equipment: { name: '', recurrent: false },
      equipmentId: 0,
      load: false
    }
  },
  computed: {
    headers() {
      return [
        { text: this.$t('date'), value: 'date' },
        {
          text: this.equipment.recurrent ? this.$t('next_date') : '',
          value: this.equipment.recurrent ? 'next_date' : ''
        },
        { text: this.$t('description'), value: 'description' },
        { text: this.$t('operator'), value: 'operator' },
        { text: this.$t('action'), value: 'actions' }
      ]
    },
    /**
     * tableTitle
     * Computed property que genera el título de la barra de herramientas
     * dependiendo de si se está editando un mantenimiento existente o creando
     * uno nuevo.
     *
     * @returns {string} Título de la barra de herramientas.
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    tableTitle() {
      return `${this.$t('equipment_maintenance')} (${this.$t(
        this.equipment.name || ''
      )})`
    }
  },
  methods: {
    /**
     * getEquipment
     * Método para consultar el equipo según el identificador proporcionado en
     * la ruta.
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async getEquipment() {
      this.load = true
      const equipmentParam = this.$route.params.equipment
      this.equipment = equipmentParam
        ? JSON.parse(equipmentParam)
        : { name: '', recurrent: false }
      this.equipmentId = this.equipment.pk || 0

      if (!this.equipmentId) {
        await this.$router.push({ name: 'equipment' })
      }
      this.load = false
    },

    /**
     * changeRoute
     * Método para cambiar la ruta al formulario de mantenimiento de equipo.
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    changeRoute(item) {
      const routeParams = {
        name: this.equipment ? 'maintenance-pk' : 'equipment',
        params: this.equipment
          ? {
              pk: this.codification({ code: item.pk }),
              equipment: JSON.stringify(this.equipment)
            }
          : {}
      }
      this.$router.push(routeParams)
    }
  },
  mounted() {
    this.getEquipment()
  }
}
</script>
